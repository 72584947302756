import { Directive, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ValidatorFn,
} from '@angular/forms';
import { Subject, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs';
import { TextInputService } from '../services/text-input.service';

@Directive({
  standalone: true,
  selector: '[ariaInput]',
})
export class ControlValueAccessorDirective<T> implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('input', { static: false }) inputElement!: ElementRef;

  constructor(@Inject(Injector) private injectorSuper: Injector, private _textInputService: TextInputService) {}

  @Input() type: string = 'text';
  @Input() validator!: ValidatorFn | null;

  control: FormControl | undefined;
  protected _isDisabled!: boolean;
  protected _unsubscribe$ = new Subject<void>();
  private _onTouched!: () => T;

  ngOnInit(): void {
    this.setFormControl();

    this._textInputService.shakeInputObs.pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: () => {
        this.resetClass('shake-error');
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  setFormControl() {
    try {
      const formControl = this.injectorSuper.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injectorSuper.get(FormGroupDirective).getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective).form as FormControl;
          break;
      }
      this.setDisabledState(this._isDisabled);
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: T): void {
    try {
      this.control ? this.control.setValue(value) : (this.control = new FormControl(value));
    } catch (_) {
      this.control = new FormControl();
    }
  }

  registerOnChange(fn: (val: T | null) => T): void {
    this.control?.valueChanges
      .pipe(
        takeUntil(this._unsubscribe$),
        startWith(this.control.value),
        distinctUntilChanged(),
        tap((val) => fn(val))
      )
      .subscribe();
  }

  registerOnTouched(fn: () => T): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;

    if (this.control) {
      isDisabled ? this.control.disable() : this.control.enable();
    }
  }

  protected isValid(): boolean {
    if (!this.control) {
      return true;
    }

    if (!this.control.touched) {
      return true;
    }

    return this.control.valid;
  }

  private resetClass(value: string) {
    if (this.control?.valid) return;
    this.inputElement.nativeElement.classList?.remove(value);
    setTimeout(() => {
      this.inputElement.nativeElement.classList?.add(value);
    });
  }
}
