export enum AppRoutes {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  MagicLink = 'magic-link',
  InstitutionLogin = 'institution-login',
  InstitutionLoginCredentials = 'institution/login/credentials',
  InstitutionUpdateCredentials = 'institution/update/credentials',
  InstitutionAccess = 'institution-access',
  Register = 'register',
  Main = 'main',
  Settings = 'settings',
  Unauthorized = 'unauthorized',
  Pricing = 'pricing',
  PurchaseSuccess = 'purchase/success',
  Landing = '/',
}
