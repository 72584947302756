@if (control) {
<div class="w-full relative">
    <input class="my-1 py-[9px] pl-4 pr-8 bg-[#EFEFF1] rounded-[10px] w-full" [formControl]="control"
        [placeholder]="placeholder" #input [type]="type" [name]="name" [id]="id"
        [class]="control.errors && control.touched || serverError && control.value || inputErrorState ? 'onboarding-input-error shake-error' : 'onboarding-input'">

    <img *ngIf="type === 'password' || showPassword" class="absolute right-3 z-10 top-3.5 cursor-pointer"
        src="assets/icons/btns/{{showPassword ? 'closed' : 'open'}}-eye.svg" alt="" (click)="displayPassword()">

    <div *ngIf="control.errors && control.touched && showError">
        <p class="onboarding-text-error text-[10px] ml-4" *ngIf="control.errors['required']">
            {{ name ?? 'This field ' }} is required</p>
        <p class="onboarding-text-error text-[10px] ml-4" *ngIf="control.errors['email']">
            {{ name ?? 'This field '}} does not meet requirements</p>
        <p class="onboarding-text-error text-[10px] ml-4" *ngIf="control.errors['pattern']">
            {{ name ?? 'This field '}} does not meet requirements</p>

        <p class="onboarding-text-error text-[10px] ml-4" *ngIf="control.errors['emailExists']">
            This email is used by another account</p>
    </div>

    <div>
        <p class="onboarding-text-error text-[10px] ml-4" *ngIf="serverError && control.value">
            {{ serverError }}</p>
    </div>
</div>
}