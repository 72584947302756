<aria-onboarding-wrapper [showBack]="true">
    <div body>
        <div class="3xs:text-xl sm:text-3xl mt-2 px-8 text-center">
            Login with {{institution?.name}}
        </div>

        <form [formGroup]="authenticationForm" class="w-full flex flex-col justify-center text-sm">
            <div class="pb-4">
                <div *ngFor="let item of institution?.authenticationForm; let i = index"
                    class="w-full flex justify-center">
                    <div class="mb-4" *ngIf="item?.inputName" class="max-w-80 w-80">
                        <aria-text-input class="w-full" [placeholder]="item.inputDisplayedName!"
                            [formControlName]="'field' + i" [id]="'institution-' + item?.inputName"
                            [name]="'Institution ' + item?.inputDisplayedName"
                            [type]="item?.inputType === 2 ? 'password' : (item?.inputType! | inputType)"
                            [showError]="showErrorMessages" [inputErrorState]="!!apiErrorMessage"
                            [serverError]="i === institution.authenticationForm!.length - 1 ? apiErrorMessage : ''"></aria-text-input>
                    </div>
                </div>
            </div>

            <div class="px-8" *ngIf="!institution?.authenticationForm || !institution?.authenticationForm?.length">
                Authentication fields wasn't configured yet for your institution, please contact your librarian.
            </div>

            <div class="w-full flex justify-center">
                <div class="w-80">
                    <aria-submit-button [loading]="loading"
                        *ngIf="institution?.authenticationForm && institution?.authenticationForm?.length" text="Login"
                        (onButtonPressed)="submit()"></aria-submit-button>
                </div>
            </div>
        </form>
    </div>
</aria-onboarding-wrapper>