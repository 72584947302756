<div class="relative inline-block text-left w-full">
    <div>
        <button type="button" (click)="toggleDropdown()"
            class="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            aria-expanded="true" aria-haspopup="true">

            <div *ngIf="selectedItem; else missingItem">
                {{selectedItem | displayFn : bindProperty}}
            </div>
            <svg class="-mr-1 ml-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.44l3.71-3.21a.75.75 0 011.04 1.08l-4.25 3.67a.75.75 0 01-.99 0L5.23 8.3a.75.75 0 01-.02-1.06z"
                    clip-rule="evenodd" />
            </svg>
        </button>
    </div>

    <div *ngIf="isDropdownOpen"
        class="overflow-y-auto origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg max-h-40 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
            <a *ngFor="let item of items" (click)="onSelectionChange(item)"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"> {{ item | displayFn : bindProperty }}
            </a>
        </div>
    </div>
</div>

<ng-template #missingItem>
    <div>
        Select your institution
    </div>
</ng-template>