<aria-onboarding-wrapper [showBack]="true">
    <div body class="flex flex-col items-center justify-center">
        <div class="3xs:text-xl sm:text-3xl mt-2 px-8 text-center pb-4">
            Complete registration for {{institutionCustomAuthModel?.name}}
        </div>

        <form [formGroup]="formGroup" class="px-4 max-w-80 text-sm">
            <div>
                <aria-text-input formControlName="firstName" id="aria-first-name-input" placeholder="First name"
                    name="FistName"></aria-text-input>
            </div>

            <div>
                <aria-text-input formControlName="lastName" id="aria-last-name-input" placeholder="Last name"
                    name="LastName"></aria-text-input>
            </div>

            <div class="form-group">
                <aria-text-input formControlName="email" id="aria-email-input" type="email" name="Email"
                    placeholder="Email address" [serverError]="serverErrors"></aria-text-input>
            </div>

            <div class="pb-[25px] mt-2 px-2 flex flex-row">
                <aria-check-box (valueEmitted)="checkTermsAndConditions()"></aria-check-box>

                <div>
                    <p class="text-[10px] font-marfa-light leading-3 text-start max-w-52"
                        [class.text-[#F3014A]]="showTermsAndConditionError">
                        By creating an account you agree to our <a href="https://www.nkoda.com/legal/end-user-agreement"
                            class="font-marfa">Terms of
                            Service</a>
                        and <a href="https://www.nkoda.com/legal/privacy-policy" class="font-marfa">Privacy Policy</a>
                    </p>
                </div>
            </div>
            <aria-submit-button [loading]="loading" text="Complete registration"
                (onButtonPressed)="submit()"></aria-submit-button>
        </form>
    </div>
</aria-onboarding-wrapper>