import { Pipe, PipeTransform } from '@angular/core';
import { InputType } from '../constants/appConstants';

@Pipe({
  name: 'inputType',
  standalone: true,
})
export class InputTypePipe implements PipeTransform {
  transform(value: number): string {
    return InputType[value].toString().toLocaleLowerCase();
  }
}
