<div class="w-full">
    <div>
        <div class="mb-2">
            If your institution is using a specialised authentication option, please search in the list below.
        </div>
        <div class="pb-4 text-gray-400 text-xs">
            Please check with your institution`s librarian to find out if this applies to you.
        </div>

        <aria-selector [items]="institutions" bindProperty="name"
            (selectionChange)="onInstitutionSelected($event)"></aria-selector>
    </div>


    <div class="text-gray-300 text-xs pt-4">
        If in doubt, or if you don`t find your institution in either list, always check with your librarian for your
        access method.
    </div>

    <div class="hide-md flex justify-center mt-4">
        <aria-submit-button text="Next" class="w-[212px]" [loading]="loading"
            (onButtonPressed)="next()"></aria-submit-button>
    </div>
</div>