import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { AppTheme } from '../../../constants/themeConstans';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'aria-content-panel-work-skeletons',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule, SvgIconComponent],
  templateUrl: './content-panel-work-skeletons.component.html',
  styleUrl: './content-panel-work-skeletons.component.scss',
})
export class ContentPanelWorkSkeletonsComponent implements OnInit {
  darkBackgroundColor = '#3c3c3c';
  lightBackgroundColor = '#eff1f6';

  backgroundColor = this.lightBackgroundColor;

  imageTheme = { height: '94px', width: '75px', 'background-color': this.backgroundColor };
  titleLineTheme = { height: '14px', 'margin-bottom': '0px', 'background-color': this.backgroundColor };
  subTitleLineTheme = {
    height: '11px',
    'margin-bottom': '0px',
    'background-color': this.backgroundColor,
  };

  currentTheme: AppTheme = AppTheme.White;

  animation: 'progress-dark' | 'progress' = 'progress';

  private _unsubscribeObs$ = new Subject();
  constructor(private _themeService: ThemeService) {}

  ngOnInit(): void {
    this._unsubscribeObs$ = new Subject();
    this._themeService._themeObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe((currentTheme) => {
      this.backgroundColor = currentTheme === AppTheme.Dark ? this.darkBackgroundColor : this.lightBackgroundColor;

      this.imageTheme = { height: '94px', width: '75px', 'background-color': this.backgroundColor };
      this.titleLineTheme = { height: '14px', 'margin-bottom': '0px', 'background-color': this.backgroundColor };
      this.subTitleLineTheme = {
        height: '11px',
        'margin-bottom': '0px',
        'background-color': this.backgroundColor,
      };

      this.animation = currentTheme === AppTheme.Dark ? 'progress-dark' : 'progress';
    });
  }
}
