import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  transform(value: string | undefined | null, limit: number): string {
    if (!value) return '';
    if (value.length <= limit) return value;

    const truncated = value.slice(0, limit);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    return truncated.slice(0, lastSpaceIndex) + ' ...';
  }

}
