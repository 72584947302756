import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, take } from 'rxjs';
import { CustomInstitutionAuthComponent } from '../../components/custom-institution-auth/custom-institution-auth.component';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { TextInputComponent } from '../../components/onboarding/text-input/text-input.component';
import { ProviderComponent } from '../../components/provider/provider.component';
import { AppRoutes } from '../../constants/appRoutes';
import { Providers } from '../../constants/authConstants';
import { AuthService } from '../../services/auth.service';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'aria-institution-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TextInputComponent,
    ReactiveFormsModule,
    ProviderComponent,
    NgxSkeletonLoaderModule,
    OnboardingWrapperComponent,
    CustomInstitutionAuthComponent,
  ],
  templateUrl: './institution-login.component.html',
  styleUrl: './institution-login.component.scss',
})
export class InstitutionLoginComponent implements OnInit, OnDestroy {
  control = new FormControl('');
  providers = Providers;

  private _unsubscribe$!: Subject<void>;

  constructor(private _location: Location, private _router: Router, private _authService: AuthService) {}

  ngOnInit(): void {
    this._unsubscribe$ = new Subject();

    this._authService.nonceObs$.pipe(take(1)).subscribe({
      next: (nonce: string) => {
        const currentURL = this._router.url;
        const params = `sessionId=${StringUtils.uuidv4()}&source=aria&nonce=${nonce}&redirectUri=${location.origin}/${
          AppRoutes.Main
        }`;

        if (currentURL.includes('nonce') || currentURL.includes('state')) {
          const paramsURL = `${location.origin}${location.pathname}?${params}`;
          window.history.replaceState({ path: paramsURL }, '', paramsURL);
        } else {
          const paramsURL = currentURL.includes('?') ? currentURL + '&' + params : currentURL + '?' + params;
          window.history.replaceState({ path: paramsURL }, '', paramsURL);
        }

        this.addWayfinderScript();
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  goBack() {
    this._location.back();
  }

  private addWayfinderScript(): HTMLScriptElement {
    const wayfinderScript = document.createElement('script');
    wayfinderScript.id = 'wayfinder_script_nkoda';
    wayfinderScript.type = 'text/javascript';
    wayfinderScript.defer = true;
    wayfinderScript.src = `/assets/scripts/wayfinder.js`;
    document.head.appendChild(wayfinderScript);

    return wayfinderScript;
  }
}
