<div class="h-full relative pr-2 overflow-x-auto scroll-hide">
    <!-- In future if it will be decided to add tab skeletons uncomment this -->
    <!-- <div class="flex flex-row">
        <div class="flex flex-row items-center px-2 py-1 cursor-pointer">
            <div class="w-20 flex flex-row items-center border-b-[1px] border-b-[#8b8b8b]">
                <svg-icon src="/assets/icons/work.svg" class="mr-1"
                    [svgStyle]="{'color': 'var(--text-primary)', 'margin-top': '-2px'}"></svg-icon>
                <ngx-skeleton-loader [theme]="{  height: '24px', 'margin-bottom': '-4px' }" class="w-20" count="1"
                    appearance="line"></ngx-skeleton-loader>
            </div>

            <div class="w-32 flex flex-row items-center ml-2">
                <svg-icon src="/assets/icons/composer.svg" class="ml-1 mr-1"
                    [svgStyle]="{'color': 'var(--text-primary)', 'margin-top': '-2px'}"></svg-icon>
                <ngx-skeleton-loader [theme]="{  height: '24px', 'margin-bottom': '-4px' }" class="w-28" count="1"
                    appearance="line"></ngx-skeleton-loader>
            </div>
        </div>
    </div> -->

    <div class="mt-4">
        <div class="flex flex-row justify-between w-full pl-1" *ngFor="let item of [0, 1, 2]; let i = index">
            <div class="w-28">
                <ngx-skeleton-loader [theme]="imageTheme" count="1" appearance="line" [animation]="animation"
                    class="rounded-xl"></ngx-skeleton-loader>
            </div>

            <div class="w-full flex justify-between pl-3 h-[94px]">
                <div class="flex flex-col justify-between h-full w-full">
                    <div>
                        <div class="flex flex-col">
                            <a class="text-sm cursor-pointer text-ellipsis overflow-hidden">
                                <ngx-skeleton-loader count="1" appearance="line" [animation]="animation"
                                    [theme]="titleLineTheme"></ngx-skeleton-loader>
                            </a>
                            <a class="text-sm text-ellipsis overflow-hidden text-[#8c8c8c]">
                                <ngx-skeleton-loader count="1" appearance="line" [animation]="animation"
                                    [theme]="subTitleLineTheme"></ngx-skeleton-loader>
                            </a>
                        </div>
                    </div>

                    <div>
                        <div class="flex flex-col">
                            <a class="text-xs text-[#8b8b8b] cursor-pointer w-28" target="_blank">
                                <ngx-skeleton-loader count="1" appearance="line" [animation]="animation"
                                    [theme]="subTitleLineTheme"></ngx-skeleton-loader>
                            </a>
                            <a class="text-xs w-36">
                                <ngx-skeleton-loader count="1" appearance="line" [animation]="animation"
                                    [theme]="titleLineTheme"></ngx-skeleton-loader>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>