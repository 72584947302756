import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, of, pairwise, startWith, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { InstitutionCustomAuthModel } from '../models/institution-custom-auth-model';
import { InstitutionCustomAuthPostModel } from '../models/institution-custom-auth-post-model';
import { UserInfoModel } from '../models/user-info-model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  private _removeDynamicSeatTimeout!: NodeJS.Timeout;

  private _showInstitutionBannerSubject = new BehaviorSubject<boolean>(false);
  public showInstitutionBannerObs = this._showInstitutionBannerSubject.asObservable();

  public institutionCustomAuthModel!: InstitutionCustomAuthModel;
  public customInstitutionFormToken!: string;

  constructor(private _authService: AuthService, private _httpClient: HttpClient) {
    this._authService.userObs$
      .pipe(
        startWith(null),
        pairwise(),
        filter(([prevUser, currentUser]) => Boolean(prevUser) !== Boolean(currentUser)),
        switchMap(([currentUser]) => this.userInfoChanged(currentUser))
      )
      .subscribe();
  }

  userInfoChanged(currentUser: UserInfoModel | null) {
    if (currentUser && Object.keys(currentUser).length !== 0) {
      return this.checkInstitutionalAccess();
    }

    return this.resetInstitutionState();
  }

  checkInstitutionalAccess() {
    return this.getSeatGroupInfo();
  }

  acceptInstitutionAccess() {
    this.saveToStorage(IS_INSTITUTION_MEMBER_KEY, '1');
    return of(null);
  }

  declineInstitutionAccess() {
    return of(this.saveToStorage(IS_INSTITUTION_MEMBER_KEY, '0'));
  }

  resetInstitutionState() {
    this.deleteFromStorage(IS_INSTITUTION_MEMBER_KEY);
    return of(clearTimeout(this._removeDynamicSeatTimeout));
  }

  getInstitutionalOpportunity(): Observable<null> {
    return of(null);
  }

  getCustomAuthInstitutionList() {
    return this._httpClient.get<Array<InstitutionCustomAuthModel>>(
      `${environment.platformApiUrl}/v4/institution/customAuthentication/all`
    );
  }

  customAuthFormPost(body: InstitutionCustomAuthPostModel) {
    return this._httpClient.post<string>(`${environment.platformApiUrl}/v4/institution/customAuthentication`, body);
  }

  private getSeatGroupInfo(): Observable<null> {
    return of(null);
  }

  private deleteFromStorage(key: string) {
    localStorage.removeItem(key);
  }

  private saveToStorage(key: string, data: string) {
    localStorage.setItem(key, data);
  }
}

export const IS_INSTITUTION_MEMBER_KEY = 'is-institution-member';
