import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../../constants/appRoutes';
import { AuthenticationType } from '../../constants/authConstants';
import { InstitutionCustomAuthModel } from '../../models/institution-custom-auth-model';
import { AuthService } from '../../services/auth.service';
import { InstitutionService } from '../../services/institution.service';
import { SubmitButtonComponent } from '../onboarding/submit-button/submit-button.component';
import { SelectorComponent } from '../selector/selector.component';

@Component({
  selector: 'aria-custom-institution-auth',
  standalone: true,
  imports: [CommonModule, SubmitButtonComponent, SelectorComponent],
  templateUrl: './custom-institution-auth.component.html',
  styleUrl: './custom-institution-auth.component.scss',
})
export class CustomInstitutionAuthComponent implements OnInit {
  institutions: Array<InstitutionCustomAuthModel> = [];
  selectedInstitution: InstitutionCustomAuthModel = {};

  loading = false;

  constructor(
    private _institutionService: InstitutionService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._institutionService.getCustomAuthInstitutionList().subscribe({
      next: (institutions) => {
        this.institutions = institutions;
      },
    });
  }

  next() {
    if (this.selectedInstitution.authenticationType === AuthenticationType.OIDC) {
      if (this.selectedInstitution?.oidcSettings?.infoToken) {
        this.loading = true;
        this._authService.loginExternalWithGenericOidc(this.selectedInstitution?.oidcSettings?.infoToken);
        return;
      }
    }

    this._router.navigate([AppRoutes.InstitutionLoginCredentials]);
  }

  onInstitutionSelected(institution: InstitutionCustomAuthModel) {
    this.selectedInstitution = institution;
    this._institutionService.institutionCustomAuthModel = institution;
  }
}
