import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { OnboardingWrapperComponent } from '../../../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { SubmitButtonComponent } from '../../../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../../../components/onboarding/text-input/text-input.component';
import { AppRoutes } from '../../../../constants/appRoutes';
import { GrantErrorActions } from '../../../../constants/authConstants';
import { InstitutionCustomAuthFormModel } from '../../../../models/institution-custom-auth-form-model';
import { InstitutionCustomAuthModel } from '../../../../models/institution-custom-auth-model';
import { InstitutionCustomAuthPostModel } from '../../../../models/institution-custom-auth-post-model';
import { InputTypePipe } from '../../../../pipes/input-type.pipe';
import { AuthService } from '../../../../services/auth.service';
import { InstitutionService } from '../../../../services/institution.service';

@Component({
  selector: 'aria-institution-credentials-step',
  standalone: true,
  imports: [
    CommonModule,
    OnboardingWrapperComponent,
    ReactiveFormsModule,
    TextInputComponent,
    InputTypePipe,
    SubmitButtonComponent,
  ],
  templateUrl: './institution-credentials-step.component.html',
  styleUrl: './institution-credentials-step.component.scss',
})
export class InstitutionCredentialsStepComponent implements OnInit {
  institution!: InstitutionCustomAuthModel;
  apiErrorMessage: string = '';
  authenticationForm!: FormGroup;
  userCredentials: InstitutionCustomAuthPostModel = {
    authenticationFormModels: [],
  };
  loading = false;
  showErrorMessages = false;

  constructor(private _institution: InstitutionService, private _router: Router, private _authService: AuthService) {}

  ngOnInit(): void {
    this.institution = this._institution.institutionCustomAuthModel;
    if (!this.institution) {
      this._router.navigate([AppRoutes.InstitutionLogin]);
    }

    this.createFormGroupObject();
  }

  createFormGroupObject() {
    const formGroupObject = {};
    if (!this.institution?.authenticationForm) return;

    const authenticationFormArray = Object.entries(this.institution?.authenticationForm);
    authenticationFormArray.forEach((x) => {
      if (x[1]?.inputName) {
        Object.assign(formGroupObject, { ['field' + x[0]]: new FormControl('', Validators.required) });
      }
    });

    this.authenticationForm = new FormGroup(formGroupObject);
  }

  submit() {
    if (this.authenticationForm?.status === 'INVALID') {
      this.showErrorMessages = true;
      this.authenticationForm.markAllAsTouched();
      this.authenticationForm.markAsDirty();
      return;
    }

    this.loading = true;
    this.userCredentials.institutionRef = this.institution.id;
    Object.entries(this.institution.authenticationForm!).forEach((element, index) => {
      this.userCredentials.authenticationFormModels?.push({
        inputName: element[1].inputName,
        isRequired: element[1].isRequired,
        inputValue: this.authenticationForm.get('field' + index)?.value,
      } as InstitutionCustomAuthFormModel);
    });

    this._institution
      .customAuthFormPost(this.userCredentials)
      .pipe(
        switchMap((token) => {
          this._institution.customInstitutionFormToken = token;
          return this._authService.loginInstitutionGrant({ token });
        })
      )
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Main]);
          return;
        },
        error: (error) => {
          this.showErrorMessages = true;
          if (error.error.error_action === GrantErrorActions.RegistrationRequired) {
            this._router.navigate([AppRoutes.InstitutionUpdateCredentials]);
            return;
          }

          if (error.error.AdditionalData) {
            this.apiErrorMessage = error
              ? error.error.AdditionalData.InstitutionError
              : 'Your email or password is incorrect';
          }

          if (error.error.Message && !error.error.AdditionalData) {
            this.apiErrorMessage = 'Your email or password is incorrect';
          }
        },
      })
      .add(() => {
        this.userCredentials = {
          authenticationFormModels: [],
        };

        this.loading = false;
      });
  }
}
