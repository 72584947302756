import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayFn',
  standalone: true,
})
export class DisplayFnPipe implements PipeTransform {
  transform<T>(item: T, displayProperty: string): string {
    if (!item || !displayProperty) {
      return '';
    }

    return (item as Record<string, unknown>)[displayProperty]?.toString() ?? '';
  }
}
