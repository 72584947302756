import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ariaRipple]',
  standalone: true,
})
export class RippleDirective {
  @Input() rippleColor: string = '#99999950';
  @Input() rippleDuration: number = 1000;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const button = this.el.nativeElement;

    const ripple = this.renderer.createElement('span');
    this.renderer.setStyle(ripple, 'position', 'absolute');
    this.renderer.setStyle(ripple, 'border-radius', '50%');
    this.renderer.setStyle(ripple, 'transform', 'scale(0)');
    this.renderer.setStyle(ripple, 'opacity', '1');
    this.renderer.setStyle(ripple, 'pointer-events', 'none');
    this.renderer.setStyle(ripple, 'background-color', this.rippleColor);
    this.renderer.setStyle(
      ripple,
      'transition',
      `transform ${this.rippleDuration}ms ease, opacity ${this.rippleDuration}ms ease`
    );

    this.renderer.appendChild(button, ripple);

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    this.renderer.setStyle(ripple, 'width', `${size}px`);
    this.renderer.setStyle(ripple, 'height', `${size}px`);
    this.renderer.setStyle(ripple, 'top', `${y}px`);
    this.renderer.setStyle(ripple, 'left', `${x}px`);

    setTimeout(() => {
      this.renderer.setStyle(ripple, 'transform', 'scale(4)');
      this.renderer.setStyle(ripple, 'opacity', '0');
    }, 0);

    setTimeout(() => {
      this.renderer.removeChild(button, ripple);
    }, this.rippleDuration);
  }
}
