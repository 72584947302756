export const EmailRegEx =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export class PasswordRegex {
  static atLeastEightCharacters = /.{8,}/;
  static atLeastOneUpperCaseCharacter = /.*[A-Z].*/;
  static atLeastOneLowerCaseCharacter = /.*[a-z].*/;
  static atLeastOneNumber = /.*[0-9].*/;
  static atLeastOneSpecialCharacter = /.*[!@#$%^&*()\-_+=].*/;
}

export const PasswordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i;

export enum Providers {
  Google = 'Google',
  Apple = 'Apple',
  Facebook = 'Facebook',
  Institution = 'Institution',
  Weibo = 'Weibo',
  WeChat = 'WeChat',
}

export enum CustomProviders {
  GenericOIDC = 'GenericOIDC',
}

export enum GrantType {
  AuthToken = 'nkoda_auth_token',
  InstitutionToken = 'nkoda_institution_token',
}

export enum GrantErrorActions {
  RegistrationRequired = 'registration_required',
}

export enum ResponseType {
  Code = 'code',
  Implicit = 'id_token token',
}

export enum AuthenticationType {
  IpRange,
  EzProxy,
  OpenAthens,
  MemberCode,
  GSuite,
  Custom,
  OIDC,
}
