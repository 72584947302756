import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ModalService } from 'ngx-modal-ease';
import { Subject, takeUntil } from 'rxjs';
import { ProductDto, ThresholdInfo } from '../../api/aria/models';
import { AppRoutes } from '../../constants/appRoutes';
import { RippleDirective } from '../../directives/ripple.directive';
import { SessionModel } from '../../models/session-model';
import { TimeCreatedType } from '../../models/time-created-type';
import { UserInfoModel } from '../../models/user-info-model';
import { AuthService } from '../../services/auth.service';
import { ClientThresholdService } from '../../services/client-threshold.service';
import { ConnectionService } from '../../services/connection.service';
import { HeaderService } from '../../services/header.service';
import { OpenAiService } from '../../services/open-ai.service';
import { SidebarService } from '../../services/sidebar.service';
import { SubscriptionInfoService } from '../../services/subscription-info.service';
import { CloseSidebarComponent } from '../close-sidebar/close-sidebar.component';
import { SubscriptionManagementComponent } from '../subscription-management/subscription-management.component';

@Component({
  selector: 'aria-sidebar',
  standalone: true,
  imports: [CommonModule, CloseSidebarComponent, SvgIconComponent, RippleDirective],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  @Output() isClosed = new EventEmitter<boolean>();
  @Input() user!: UserInfoModel;

  sessionModels: Array<SessionModel> = [];
  sideBarStatus = window.innerWidth <= 768;

  isSubscriptionManagementAvailable: boolean = false;

  organizedSession: Record<number, Array<SessionModel>> = {
    0: [],
    1: [],
    2: [],
  };

  showUserSettings: boolean = false;
  thresholdInfo!: ThresholdInfo;

  get keyValuePairs() {
    return Object.keys(this.organizedSession);
  }

  private _unsubscribeObs$: Subject<void>;

  constructor(
    private _openAiService: OpenAiService,
    private _sidebarService: SidebarService,
    private _authService: AuthService,
    private _router: Router,
    private _elementRef: ElementRef,
    private _modalService: ModalService,
    private _connectionService: ConnectionService<string>,
    private _thresholdService: ClientThresholdService,
    private _subscriptionService: SubscriptionInfoService,
    private _headerService: HeaderService
  ) {
    this._unsubscribeObs$ = new Subject<void>();
  }

  ngOnInit(): void {
    this._openAiService.sessionModelObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (sessionModels: Array<SessionModel>) => {
        this.organizedSession = {
          0: [],
          1: [],
          2: [],
        };

        const dateTimeNow = new Date();
        const oneDayInterval = new Date(new Date().setDate(dateTimeNow.getDate() - 1));
        const sevenDaysInterval = new Date(new Date().setDate(dateTimeNow.getDate() - 7));

        this.sessionModels = sessionModels.map((x) => {
          const updatedAt = new Date(x.updatedAt as string);
          if (oneDayInterval < updatedAt) {
            this.organizedSession[TimeCreatedType.Today].push(x);
            return x;
          }

          if (sevenDaysInterval < updatedAt) {
            this.organizedSession[TimeCreatedType.Previous7Days].push(x);
            return x;
          }

          this.organizedSession[TimeCreatedType.Previous30Days].push(x);
          return x;
        });
      },
    });

    this._subscriptionService.b2cProductsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (products: Array<ProductDto>) => {
        this.isSubscriptionManagementAvailable = products.filter((x) => !x.isDefault)?.length > 0;
      },
    });

    this._sidebarService.showSidebarObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (sideBarStatus) => {
        this.sideBarStatus = sideBarStatus;
      },
    });

    this._thresholdService.userThresholdObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (threshold) => {
        this.thresholdInfo = threshold;
      },
    });
  }

  logout() {
    this._authService
      .logout()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Login]);
        },
      });
  }

  openSubscriptionManagement() {
    this._modalService.open(SubscriptionManagementComponent, {
      modal: {
        enter: 'ease-out 1s',
      },
      overlay: {
        leave: 'fade-out 1s',
        backgroundColor: 'var(--main-surface-primary)',
      },
      size: {
        width: '100%',
        height: '100%',

        padding: '0px',
      },
    });
  }

  createSession() {
    this._connectionService.sendDisconnect();
    this._openAiService
      .createSession()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._headerService.showCategorySelector();
        },
      });
  }

  selectSession(item: SessionModel) {
    this._connectionService.sendDisconnect();
    this._openAiService
      .selectSession(item)
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          if (window.innerWidth <= 768) this._sidebarService.hide();
        },
      });
  }

  deleteSession(item: SessionModel) {
    this._openAiService
      .deleteSession(item)
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          if (window.innerWidth <= 768) this._sidebarService.hide();
        },
      });
  }

  showOrHide() {
    this._sidebarService.showHide();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Event) {
    if (!this.showUserSettings) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showUserSettings = !this.showUserSettings;
    }
  }

  private checkIsDesktop() {
    return window.innerWidth >= 1280;
  }

  @HostListener('window:orientationchange')
  @HostListener('window:resize')
  onResize() {
    const isDesktopView = this.checkIsDesktop();
    if (!isDesktopView) {
      this._sidebarService.hide();
    }
  }
}
