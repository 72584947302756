import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive';
import { InputType } from '../../../models/input-type';

@Component({
  selector: 'aria-text-input',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class TextInputComponent<T> extends ControlValueAccessorDirective<T> implements InputType {
  @Input() id = '';
  @Input() css: InputType['css'] = 'input-primary';
  @Input() placeholder?: string | undefined;
  @Input() value?: string | number | undefined;
  @Input() customErrorMessages: Record<string, string> = {};
  @Input() name: string | null = null;
  @Input() showError = true;
  @Input() inputErrorState = false;
  @Input() serverError = '';
  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  showPassword!: boolean;

  displayPassword() {
    this.showPassword = !this.showPassword;
    if (this.type === 'password') {
      this.type = 'text';
      return;
    }

    this.type = 'password';
  }
}
