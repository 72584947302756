/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiLinkPost$Json } from '../fn/link/api-link-post-json';
import { ApiLinkPost$Json$Params } from '../fn/link/api-link-post-json';
import { apiLinkPost$Plain } from '../fn/link/api-link-post-plain';
import { ApiLinkPost$Plain$Params } from '../fn/link/api-link-post-plain';
import { apiLinkPut$Json } from '../fn/link/api-link-put-json';
import { ApiLinkPut$Json$Params } from '../fn/link/api-link-put-json';
import { apiLinkPut$Plain } from '../fn/link/api-link-put-plain';
import { ApiLinkPut$Plain$Params } from '../fn/link/api-link-put-plain';
import { apiLinkSessionIdGet$Json } from '../fn/link/api-link-session-id-get-json';
import { ApiLinkSessionIdGet$Json$Params } from '../fn/link/api-link-session-id-get-json';
import { apiLinkSessionIdGet$Plain } from '../fn/link/api-link-session-id-get-plain';
import { ApiLinkSessionIdGet$Plain$Params } from '../fn/link/api-link-session-id-get-plain';
import { NkodaLinkDto } from '../models/nkoda-link-dto';
import { NkodaLinksDto } from '../models/nkoda-links-dto';

@Injectable({ providedIn: 'root' })
export class LinkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiLinkSessionIdGet()` */
  static readonly ApiLinkSessionIdGetPath = '/api/link/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkSessionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkSessionIdGet$Plain$Response(params: ApiLinkSessionIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinksDto>> {
    return apiLinkSessionIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkSessionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkSessionIdGet$Plain(params: ApiLinkSessionIdGet$Plain$Params, context?: HttpContext): Observable<NkodaLinksDto> {
    return this.apiLinkSessionIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinksDto>): NkodaLinksDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkSessionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkSessionIdGet$Json$Response(params: ApiLinkSessionIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinksDto>> {
    return apiLinkSessionIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkSessionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkSessionIdGet$Json(params: ApiLinkSessionIdGet$Json$Params, context?: HttpContext): Observable<NkodaLinksDto> {
    return this.apiLinkSessionIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinksDto>): NkodaLinksDto => r.body)
    );
  }

  /** Path part for operation `apiLinkPut()` */
  static readonly ApiLinkPutPath = '/api/link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPut$Plain$Response(params?: ApiLinkPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinkDto>> {
    return apiLinkPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPut$Plain(params?: ApiLinkPut$Plain$Params, context?: HttpContext): Observable<NkodaLinkDto> {
    return this.apiLinkPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinkDto>): NkodaLinkDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPut$Json$Response(params?: ApiLinkPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinkDto>> {
    return apiLinkPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPut$Json(params?: ApiLinkPut$Json$Params, context?: HttpContext): Observable<NkodaLinkDto> {
    return this.apiLinkPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinkDto>): NkodaLinkDto => r.body)
    );
  }

  /** Path part for operation `apiLinkPost()` */
  static readonly ApiLinkPostPath = '/api/link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPost$Plain$Response(params?: ApiLinkPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinksDto>> {
    return apiLinkPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPost$Plain(params?: ApiLinkPost$Plain$Params, context?: HttpContext): Observable<NkodaLinksDto> {
    return this.apiLinkPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinksDto>): NkodaLinksDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPost$Json$Response(params?: ApiLinkPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<NkodaLinksDto>> {
    return apiLinkPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLinkPost$Json(params?: ApiLinkPost$Json$Params, context?: HttpContext): Observable<NkodaLinksDto> {
    return this.apiLinkPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<NkodaLinksDto>): NkodaLinksDto => r.body)
    );
  }

}
