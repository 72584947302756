import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _categorySelectorState = new Subject<boolean>();
  public categorySelectorObs = this._categorySelectorState.asObservable();

  constructor() {}

  showCategorySelector() {
    this._categorySelectorState.next(true);
  }

  hideCategorySelector() {
    this._categorySelectorState.next(false);
  }
}
