import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckBoxComponent } from '../../../../components/onboarding/check-box/check-box.component';
import { OnboardingWrapperComponent } from '../../../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { SubmitButtonComponent } from '../../../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../../../components/onboarding/text-input/text-input.component';
import { AppRoutes } from '../../../../constants/appRoutes';
import { IdentityModel } from '../../../../models/identity-model';
import { AuthService } from '../../../../services/auth.service';
import { InstitutionService } from '../../../../services/institution.service';
import { emailAvailability } from '../../../../validators/email-availability.validator';
import { InstitutionCustomAuthFormModel } from './../../../../models/institution-custom-auth-form-model';
import { InstitutionCustomAuthModel } from './../../../../models/institution-custom-auth-model';

@Component({
  selector: 'aria-institution-update-step',
  standalone: true,
  imports: [
    OnboardingWrapperComponent,
    TextInputComponent,
    SubmitButtonComponent,
    CheckBoxComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './institution-update-step.component.html',
  styleUrl: './institution-update-step.component.scss',
})
export class InstitutionUpdateStepComponent implements OnInit {
  showTermsAndConditionError = false;
  isTermAndConditionAccepted = false;
  showErros = false;
  loading = false;
  serverErrors = '';

  formGroup!: FormGroup;

  institutionCustomAuthFormModel!: InstitutionCustomAuthFormModel;
  institutionCustomAuthModel!: InstitutionCustomAuthModel;
  token!: string;

  constructor(
    private _institutionService: InstitutionService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    if (!this._institutionService.institutionCustomAuthModel) {
      this._router.navigate([AppRoutes.InstitutionLogin]);
      return;
    }

    this.formGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [emailAvailability(this._authService)],
      }),
    });

    this.token = this._institutionService.customInstitutionFormToken;
    this.institutionCustomAuthModel = this._institutionService.institutionCustomAuthModel;
  }

  submit() {
    if (!this.formGroup.valid) {
      this.showErros = true;
      this.formGroup.markAllAsTouched();
      this.formGroup.markAsDirty();
      return;
    }

    if (!this.isTermAndConditionAccepted) {
      this.showTermsAndConditionError = true;
      return;
    }

    this.loading = true;

    const identityModel = {
      token: this.token,
      firstName: this.formGroup.get('firstName')?.value,
      lastName: this.formGroup.get('lastName')?.value,
      userName: this.formGroup.get('email')?.value,
    } as IdentityModel;

    this._authService
      .loginInstitutionGrant(identityModel)
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Main]);
        },
        error: (errors) => {
          this.serverErrors = errors?.error?.message;
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  checkTermsAndConditions() {
    this.isTermAndConditionAccepted = !this.isTermAndConditionAccepted;
    this.showTermsAndConditionError = false;
  }
}
