import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NkodaLinksDto } from '../api/aria/models';
import { SessionState } from '../models/content-panel-state';

@Injectable({
  providedIn: 'root',
})
export class NkodaLinkPanelService {
  private _state = false;
  private _data: NkodaLinksDto = {};

  private sessionState = new BehaviorSubject<SessionState>(SessionState.InitSession);
  public sessionStateObs = this.sessionState.asObservable();

  private showSubject = new Subject<boolean>();
  public showObs = this.showSubject.asObservable();

  private requestLoadingSubject = new BehaviorSubject<boolean>(false);
  public requestLoadingOb$ = this.requestLoadingSubject.asObservable();

  private panelDataSubject = new BehaviorSubject<NkodaLinksDto>(this._data);
  public panelDataObs$ = this.panelDataSubject.asObservable();

  constructor() {}

  set data(items: NkodaLinksDto) {
    this._data = this.data;
    this.panelDataSubject.next(items);
  }

  showHide() {
    this._state = !this._state;
    this.showSubject.next(this._state);
  }

  hide() {
    this._state = true;
    this.showSubject.next(this._state);
  }

  show() {
    this._state = false;
    this.showSubject.next(this._state);
  }

  startLoading() {
    this.requestLoadingSubject.next(true);
  }

  endLoading() {
    this.requestLoadingSubject.next(false);
  }
  setSessionState(state: SessionState) {
    this.sessionState.next(state);
  }
}
