import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { LottieComponent } from 'ngx-lottie';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { ComposerNkodaLinkDto, NkodaLinksDto } from '../../api/aria/models';
import { SwiperDirective } from '../../directives/swiper.directive';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { ChatSectionService } from '../../services/chat-section.service';
import { NkodaLinkPanelService } from '../../services/nkoda-link-panel.service';
import { NkodaLinkEditionListComponent } from '../nkoda-link-edition-list/nkoda-link-edition-list.component';
import { NkodaLinkTitleListComponent } from '../nkoda-link-title-list/nkoda-link-title-list.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { ContentPanelWorkSkeletonsComponent } from '../skeletons/content-panel-work-skeletons/content-panel-work-skeletons.component';
import { TabModel } from './../../models/tab-model';
import { CloseNkodaLinkPanelComponent } from './../close-nkoda-link-panel/close-nkoda-link-panel.component';
import { SessionState } from '../../models/content-panel-state';

@Component({
  selector: 'aria-nkoda-link-panel',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    SwiperDirective,
    CloseNkodaLinkPanelComponent,
    NgxSkeletonLoaderModule,
    NkodaLinkEditionListComponent,
    PaginationComponent,
    NkodaLinkTitleListComponent,
    TruncatePipe,
    ContentPanelWorkSkeletonsComponent,
    LottieComponent,
  ],
  templateUrl: './nkoda-link-panel.component.html',
  styleUrl: './nkoda-link-panel.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NkodaLinkPanelComponent implements OnInit, OnDestroy {
  sessionState: SessionState = SessionState.InitSession;
  data: NkodaLinksDto = {};
  composers: Array<ComposerNkodaLinkDto> = [];
  shouldMessageBeDisplayed: boolean = true;
  shouldShowErrorMessage: boolean = false;
  selectedComposer!: ComposerNkodaLinkDto;
  selectedSectionId!: string;
  tab: TabModel = {
    name: 'nkoda content',
  };
  currentPageIndex = 1;

  isComposerInfoExpanded = false;
  isRequestInProgress = false;

  lottieOptions = { path: '/assets/lottie/ARIA-I_SKELETON_DOTS.json' };
  protected readonly SessionState = SessionState;
  hasNextItems = false;
  hasPrevItems = false;
  isPanelHidden = true;

  private _unsubscribeObs$ = new Subject();
  constructor(
    private _nkodaLinkPanel: NkodaLinkPanelService,
    private _chatSectionService: ChatSectionService,
  ) {}

  ngOnInit(): void {
    this._nkodaLinkPanel.sessionStateObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (state) => {
        this.sessionState = state;
        if (this.sessionState == SessionState.SendMessage || this.sessionState == SessionState.InitSession) {
          this.shouldMessageBeDisplayed = true;
          this.shouldShowErrorMessage = false;
        } else if (this.sessionState != SessionState.Scroll) {
          this.shouldMessageBeDisplayed = false;
          this.shouldShowErrorMessage = false;
        } else if (this.sessionState == SessionState.Scroll) {
          this.displayContentOrErrorMessage();
        }
      },
    });
    this._nkodaLinkPanel.showObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isHidden) => (this.isPanelHidden = isHidden),
    });

    this._nkodaLinkPanel.requestLoadingOb$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isInProgress) => (this.isRequestInProgress = isInProgress),
    });

    this._nkodaLinkPanel.panelDataObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (data) => {
        this.data = data;
        this.selectedSectionId = this._chatSectionService.getLastSectionId()!;
        this.mapData();
        if (this.sessionState == SessionState.Scroll) {
          this.displayContentOrErrorMessage();
        }
      },
    });

    this._chatSectionService.chatSectionObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (sectionId) => {
        if (!sectionId || this.selectedSectionId === sectionId) return;
        this.selectedSectionId = sectionId;
        this.mapData();
        if (this.sessionState == SessionState.Scroll) {
          this.displayContentOrErrorMessage();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  setNextItem() {
    if (this.selectedComposer.hasData) {
      return;
    }
  }

  pageChanged(pageNr: number) {
    this.currentPageIndex = pageNr;
    this.setItemByPage();
  }

  setItemByPage() {
    this.selectedComposer = this.composers[this.currentPageIndex - 1];
    console.log(this.selectedComposer);
    const composerIndex = this.composers.findIndex((x) => x.id === this.selectedComposer.id);
    this.hasPrevItems = this.currentPageIndex - 1 !== 0;
    this.hasNextItems = composerIndex !== this.composers.length - 1;

    this.setNextItem();
  }

  toggleMoreInfo() {
    this.isComposerInfoExpanded = !this.isComposerInfoExpanded;
  }
  private displayContentOrErrorMessage() {
    if (this.composers.length == 0) {
      this.shouldMessageBeDisplayed = false;
      this.shouldShowErrorMessage = true;
    } else {
      this.shouldMessageBeDisplayed = false;
      this.shouldShowErrorMessage = false;
    }
  }
  private mapData() {
    if (!this.data || !this.data.composerLinks?.length) {
      this.composers = [];
      return;
    }
    this.composers = [];
    const placeholderDate = '0001-01-01T00:00:00';
    if (this.data.composerLinks && this.data.composerLinks.length) {
      this.composers = this.data.composerLinks.filter((x) => {
        if (x.messageId === this.selectedSectionId) {
          if (x.composer?.dateOfBirth === placeholderDate) {
            x.composer.dateOfBirth = null;
          }
          if (x.composer?.dateOfDeath === placeholderDate) {
            x.composer.dateOfDeath = null;
          }
          return true;
        }
        return false;
      });

      this.selectedComposer = this.composers[0];
    }

    this._nkodaLinkPanel.show();
    setTimeout(() => {
      this.currentPageIndex = 1;
    }, 500);
  }
}
