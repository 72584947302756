<aria-onboarding-wrapper [showBack]="true">
    <div body>
        <div class="items-center flex-col flex w-full" id="institution-form-header">
            <h1 class="sm:text-[36px] 3xs:mt-4 sm:mt-10 font-[800] 3xs:leading-5 leading-9 3xs:text-[24px]">Choose
                your institution
            </h1>
            <h3 class="sm:text-sm font-[800] xs:leading-5 leading-9 xs:text-[12px] xs:mt-0 sm:mt-4">
                Institutions
                you&#8217;ve used
                previously</h3>
        </div>

        <div id="institution-form" class="w-full flex items-center flex-col mb-10">
            <div id="wayfinder" class="w-[320px] 3xs:px-5 sm:px-0 wayfinder-login">
                <div class="h-[180px] w-full">
                    <div class="mt-8">
                        <ngx-skeleton-loader count="4" appearance="line"> </ngx-skeleton-loader>

                        <div class="flex items-center justify-center">
                            <button class="h-10 w-[217px] bg-[#f1f1f1]">
                                <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-center my-4 w-full">
                <div class="flex-grow border-t border-gray-300 w-full ml-5"></div>
                <span class="mx-4 text-gray-500">or</span>
                <div class="flex-grow border-t border-gray-300 w-full mr-5"></div>
            </div>

            <div class="3xs:px-5 sm:px-28">
                <aria-custom-institution-auth></aria-custom-institution-auth>
            </div>
        </div>
    </div>
</aria-onboarding-wrapper>