import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisplayFnPipe } from '../../pipes/display-fn.pipe';

@Component({
  selector: 'aria-selector',
  standalone: true,
  imports: [CommonModule, FormsModule, DisplayFnPipe],
  templateUrl: './selector.component.html',
  styleUrl: './selector.component.scss',
})
export class SelectorComponent<T> {
  @Input() items: T[] = [];
  @Input() labelText = '';
  @Input() labelClass = '';
  /* Default name */
  @Input() bindProperty = 'name';

  @Output() selectionChange = new EventEmitter<T>();
  isDropdownOpen = false;
  selectedItem: T | undefined;

  onSelectionChange(item: T) {
    this.selectedItem = item;
    this.selectionChange.emit(item);
    this.isDropdownOpen = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
