import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, LoginOptions, OAuthEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, asyncScheduler, concatMap, from, map, of, scheduled, switchMap, tap } from 'rxjs';
import { UserService } from '../api/aria/services';
import { AppRoutes } from '../constants/appRoutes';
import { CustomProviders, GrantType } from '../constants/authConstants';
import { environment } from '../environments/environment';
import { IdentityModel } from '../models/identity-model';
import { SignUpModel } from '../models/sign-up-model';
import { TokenModel } from '../models/token-model';
import { StringUtils } from '../utils/string.utils';
import { ResponseType } from './../constants/authConstants';
import { UserInfoModel } from './../models/user-info-model';
import { NetworkService } from './network.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userSubject = new BehaviorSubject<UserInfoModel>({} as UserInfoModel);
  public userObs$ = this._userSubject.asObservable();

  private _user!: UserInfoModel;

  private _logoutSubject = new BehaviorSubject<boolean>(false);
  public logoutObs$ = this._logoutSubject.asObservable();

  constructor(
    private _oAuthService: OAuthService,
    private _userService: UserService,
    private _settingsService: SettingsService,
    private _httpClient: HttpClient,
    private _networkService: NetworkService
  ) {
    this.configureOAuthClient();
  }

  get user() {
    return this._user;
  }

  bootstrap(): Observable<UserInfoModel | null> {
    this.configureOAuthClient();
    const loadDiscoveryObs$ = from(
      this._oAuthService.loadDiscoveryDocumentAndTryLogin({
        disableOAuth2StateCheck: true,
        disableNonceCheck: true,
      } as LoginOptions)
    );
    return loadDiscoveryObs$
      .pipe(concatMap(() => this.silentSignIn()))
      .pipe(concatMap(() => this.loadUserInfoWithChecks()))
      .pipe(concatMap(() => this.getUserSettings()))
      .pipe(
        tap(() => {
          this._oAuthService.setupAutomaticSilentRefresh();
          this._networkService.isConnectedSubject.subscribe((connected) => {
            if (!this._oAuthService.hasValidAccessToken() && connected) {
              this.refreshToken();
            }
          });
        })
      );
  }

  silentSignIn() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authToken = urlParams.get('authToken');

    if (authToken) {
      return from(this.loginAuthToken(authToken));
    }

    return this.refreshToken();
  }

  loadUserInfoWithChecks() {
    if (this.isLoggedIn()) {
      return this.getUserInfo();
    }

    return of(null);
  }

  isAdminOrResearcher(): boolean {
    const decodedToken = jwtDecode<TokenModel>(this._oAuthService.getAccessToken());
    if (!decodedToken || !decodedToken?.role) return false;

    return decodedToken.role.includes('admin') || decodedToken.role.includes('ariaResearcher');
  }

  loginAuthToken(token: string) {
    this._oAuthService.oidc = false;
    const prepareObs$ = this.setResponseType(ResponseType.Code);
    return prepareObs$.pipe(
      switchMap(() => of((this._oAuthService.oidc = false))),
      switchMap(() =>
        this._oAuthService.fetchTokenUsingGrant(GrantType.AuthToken, {
          token,
          scope: this._oAuthService.scope,
          skipUserInfo: false,
        })
      )
    );
  }

  loginCredentials(username: string, password: string) {
    const saveCredentials = localStorage.getItem('saveCredentials') ?? 'true';
    if (saveCredentials === 'true') {
      this._oAuthService.setStorage(localStorage);
    } else {
      this._oAuthService.setStorage(sessionStorage);
    }

    const prepareObs$ = this.setResponseType(ResponseType.Code);
    return prepareObs$.pipe(
      switchMap(() => of((this._oAuthService.oidc = false))),
      switchMap(() =>
        from(this._oAuthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password)).pipe(
          tap(() => {
            this._oAuthService.setupAutomaticSilentRefresh();
          }),
          switchMap(() => this.getUserInfo()),
          switchMap(() => this.getUserSettings())
        )
      )
    );
  }

  loginExternalWithGenericOidc(infoToken: string) {
    const provider = CustomProviders.GenericOIDC;
    this.setResponseType(ResponseType.Implicit).subscribe(() => {
      localStorage.setItem('saveCredentials', 'true');
      this._oAuthService.oidc = true;
      this._oAuthService.createAndSaveNonce().then((nonce) => {
        const providerConfig = {
          scheme: 'external',
          prompt: 'select_account',
          skipUserInfo: false,
          acr_values: 'designVersion:v2',
          nonce,
          provider,
          infoToken,
        };

        this._oAuthService.initLoginFlow(provider, providerConfig);
      });
    });
  }

  loginInstitutionGrant(model: IdentityModel) {
    return from(
      this._oAuthService.fetchTokenUsingGrant(GrantType.InstitutionToken, {
        ...model,
        scope: this._oAuthService.scope,
        skipUserInfo: false,
      })
    ).pipe(switchMap(() => this.getUserInfo()));
  }

  refreshToken(): Observable<TokenResponse | OAuthEvent | null> {
    const accessToken = this._oAuthService.getAccessToken();
    if (accessToken === null || accessToken === undefined) return of(null);

    const refreshToken = this._oAuthService.getRefreshToken();

    if (!this._oAuthService.hasValidAccessToken() && !refreshToken) {
      return from(this._oAuthService.silentRefresh());
    }

    if (!this._oAuthService.hasValidAccessToken()) {
      return from(this._oAuthService.refreshToken());
    }

    return of(null);
  }

  getMagicLink(email: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('returnUrl', `${location.origin}/${AppRoutes.Main}?authToken=`);
    return this._httpClient.post<boolean>(
      `${environment.identityServerBaseUrl}/identityApi/email/magic-link`,
      formData
    );
  }

  resetPassword(email: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('designVersion', 'v2');

    return this._httpClient.post<boolean>(`${environment.identityServerBaseUrl}/api/identity/resetPassword`, formData);
  }

  isLoggedIn() {
    const accessToken = this._oAuthService.getAccessToken();
    if (accessToken === null || accessToken === undefined) return false;
    return this._oAuthService.hasValidAccessToken();
  }

  private getUserInfo(): Observable<UserInfoModel> {
    return this._httpClient
      .get<UserInfoModel>(this._oAuthService.userinfoEndpoint ?? '', {
        headers: {
          Authorization: 'Bearer ' + this._oAuthService.getAccessToken(),
        },
      })
      .pipe(
        map((x) => {
          const user = x as UserInfoModel;
          user.isAdmin = this.isAdminOrResearcher();

          if (Array.isArray(user.email)) {
            user.email = user.email[0];
          }

          if (user?.picture && user?.userInitials && user?.fullName) {
            return user;
          }

          this.getAdditionalUserData(user);

          this._user = user;
          this._settingsService.user = user;

          return user;
        })
      );
  }

  get nonceObs$() {
    return from(this._oAuthService.createAndSaveNonce());
  }

  getAuthTokenHeader() {
    return this._oAuthService.authorizationHeader();
  }

  getAuthToken() {
    return this._oAuthService.getAccessToken();
  }

  private getAdditionalUserData(user: UserInfoModel) {
    const familyName = user?.['family_name']?.substring(0, 1)?.toUpperCase() ?? '';
    const givenName = user?.['given_name']?.substring(0, 1)?.toUpperCase() ?? '';
    user.userInitials = `${givenName}${familyName}`;
    user.firstName = user?.['given_name'];
    user.lastName = user?.['family_name'];
    user.fullName =
      StringUtils.capitalizeFirstLetter(user?.['given_name']) +
      ' ' +
      StringUtils.capitalizeFirstLetter(user?.['family_name']);

    this._userSubject.next(user);
  }

  loginSocials(provider: string) {
    this.setResponseType(ResponseType.Implicit).subscribe(() => {
      localStorage.setItem('saveCredentials', 'true');
      this._oAuthService.oidc = true;
      this._oAuthService.createAndSaveNonce().then((nonce) => {
        const providerConfig = {
          scheme: 'external',
          prompt: 'select_account',
          skipUserInfo: false,
          acr_values: 'designVersion:v2',
          nonce,
          provider,
        };

        this._oAuthService.initLoginFlow(provider, providerConfig);
      });
    });
  }

  signUp(model: SignUpModel): Observable<UserInfoModel | null> {
    const signUpObs$ = this._httpClient.post<boolean>(
      environment.identityServerBaseUrl + '/api/identity/signup',
      model
    );

    this.saveCredentials(true);
    return signUpObs$.pipe(concatMap(() => this.loginCredentials(model.email, model.password)));
  }

  saveCredentials(shouldSave: boolean) {
    localStorage.setItem('saveCredentials', `${shouldSave}`);
  }

  checkEmailAvailability(email: string): Observable<boolean> {
    return this._httpClient.get<boolean>(
      environment.identityServerBaseUrl + `/identityApi/userManagement/mobileUsers/${email}/availability`
    );
  }

  private getUserSettings() {
    if (!this.isLoggedIn()) {
      return of(null);
    }

    const userSettingsObs$ = this._userService.apiUserSettingsGet$Json();
    return userSettingsObs$.pipe(
      tap((userSettings) => {
        this._settingsService.userSettings = userSettings ?? {};
      }),
      switchMap(() => of(this._userSubject.value))
    );
  }

  logout() {
    const accessToken = this._oAuthService.getAccessToken();
    const decodedToken = jwtDecode<TokenModel>(accessToken);
    const shouldIgnoreSessionEnd = decodedToken?.idp === 'local';

    localStorage.removeItem('responseType');
    localStorage.removeItem('nonce');

    this._logoutSubject.next(true);

    return of(this._oAuthService.logOut(shouldIgnoreSessionEnd));
  }

  private configureOAuthClient() {
    const responseType = this.getResponseType();
    if (responseType) {
      authConfig.responseType = responseType;
    }

    this._oAuthService.configure(authConfig);
    const saveCredentials = localStorage.getItem('saveCredentials') ?? 'true';
    const storage = saveCredentials === 'true' ? localStorage : sessionStorage;
    this._oAuthService.setStorage(storage);
  }

  private setResponseType(responseType: ResponseType) {
    localStorage.setItem('response_type', responseType);
    authConfig.responseType = responseType;
    this._oAuthService.configure(authConfig);

    return scheduled(this._oAuthService.loadDiscoveryDocument(), asyncScheduler);
  }

  private getResponseType() {
    return localStorage.getItem('response_type');
  }
}

export const authConfig: AuthConfig = {
  issuer: environment.identityServerBaseUrl,
  userinfoEndpoint: environment.identityServerBaseUrl + '/connect/userinfo',
  clientId: 'webapp',
  scope: 'openid offline_access profile email nkoda.platform.api',
  showDebugInformation: false,
  dummyClientSecret: 'K7gNU3sdo+OL0wNhqoVWhr3g6s1xYv72ol/pe/Unols=',
  redirectUri: location.origin + '/' + AppRoutes.Main,
  postLogoutRedirectUri: location.origin + '/' + AppRoutes.Login,
  logoutUrl: location.origin + '/' + AppRoutes.Login,
};
