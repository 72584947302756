import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { debounceTime, fromEvent, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ModelConfigDisplayDto, UserMessageDto } from '../../api/aria/models';
import { AiMessageComponent } from '../../components/ai-message/ai-message.component';
import { CloseSidebarComponent } from '../../components/close-sidebar/close-sidebar.component';
import { HeaderComponent } from '../../components/header/header.component';
import { NkodaLinkPanelComponent } from '../../components/nkoda-link-panel/nkoda-link-panel.component';
import { PredefinedQuestionsComponent } from '../../components/predefined-questions/predefined-questions.component';
import { UserMessageComponent } from '../../components/sender/sender.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { AppRoutes } from '../../constants/appRoutes';
import { AppTheme } from '../../constants/themeConstans';
import { ScrollDetectDirective } from '../../directives/scroll-section-detect.directive';
import { RoleType } from '../../models/role-type';
import { SessionModel } from '../../models/session-model';
import { SocketModel } from '../../models/socket-model';
import { UserInfoModel } from '../../models/user-info-model';
import { AuthService } from '../../services/auth.service';
import { ChatSectionService } from '../../services/chat-section.service';
import { ClientThresholdService } from '../../services/client-threshold.service';
import { ConnectionService } from '../../services/connection.service';
import { NkodaLinkPanelService } from '../../services/nkoda-link-panel.service';
import { OpenAiService } from '../../services/open-ai.service';
import { SettingsService } from '../../services/settings.service';
import { SidebarService } from '../../services/sidebar.service';
import { SubscriptionInfoService } from '../../services/subscription-info.service';
import { ThemeService } from '../../services/theme.service';
import { ThinkService } from '../../services/think.service';
import { StringUtils } from '../../utils/string.utils';
import { SessionState } from '../../models/content-panel-state';

@Component({
  selector: 'aria-main',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    CloseSidebarComponent,
    UserMessageComponent,
    AiMessageComponent,
    HeaderComponent,
    SidebarComponent,
    NgOptimizedImage,
    LottieComponent,
    PredefinedQuestionsComponent,
    NkodaLinkPanelComponent,
    ScrollDetectDirective,
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scroll', { static: false }) scroll!: ElementRef<HTMLDivElement>;

  messageFormGroup!: FormGroup;
  user!: UserInfoModel;

  isSendButtonDisabled = true;
  isSideBarClosed = false;

  roleTypes = RoleType;

  modelConfig!: ModelConfigDisplayDto;

  sessionModel: Array<SessionModel> = [];
  selectedSessionModel!: SessionModel;

  streamedData: string = '';
  loaded = false;
  isGenerationInProgress = false;

  whiteWave = '/assets/lottie/ARI_WAVE_WHITE.json';
  darkWave = '/assets/lottie/ARI_WAVE_DARK.json';

  lottieOptions!: AnimationOptions;

  isAutoScrollEnabled: boolean = true;
  isError: boolean = false;

  currentSection!: string;
  scrollSubscription!: Subscription;

  private _unsubscribeObs$!: Subject<void>;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _sidebarService: SidebarService,
    private _thinkingService: ThinkService,
    private _themeService: ThemeService,
    private _thresholdService: ClientThresholdService,
    private _connectionService: ConnectionService<string>,
    private _subscriptionInfo: SubscriptionInfoService,
    private _settingsService: SettingsService,
    private _nkodaLinkPanelService: NkodaLinkPanelService,
    private _chatSectionService: ChatSectionService,
    private _openAiService: OpenAiService,
  ) {}

  ngOnInit(): void {
    this.initializeComponent();
  }

  ngAfterViewInit(): void {
    this.handleTouch();
    this.handleWheel();
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  sidebarClosed(isClosed: boolean) {
    this.isSideBarClosed = isClosed;
  }

  logout() {
    this._authService
      .logout()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Login]);
        },
      });
  }

  stopGeneration() {
    if (this.isGenerationInProgress) this._connectionService.sendDisconnect();
  }

  regenerateLastMessage(aiMessage: UserMessageDto) {
    const aiMessageIndex = this.selectedSessionModel.messages.findIndex((x) => x.id === aiMessage.id);
    const userMessage = this.selectedSessionModel.messages[aiMessageIndex - 1];

    this.messageFormGroup.get('message')?.setValue(userMessage?.text);
    this.sendMessageWithGivenMessage(userMessage?.text as string);
  }

  sendMessageWithGivenMessage(givenMessage = '') {
    this.messageFormGroup.get('message')?.setValue(givenMessage);
    this.sendMessage();
  }

  sendMessage() {
    const message = this.messageFormGroup.get('message')?.value;
    this._thinkingService.startThinking();
    this.messageFormGroup.reset();
    this._thresholdService.decrementUserThreshold();
    this.setPromptAreaHeight();
    this.isGenerationInProgress = true;

    this._connectionService
      .consumeStreamData()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {},
        error: () => {
          this._thinkingService.stopAriaThinking();
          this.messageFormGroup.reset();
        },
      });

    this._connectionService.streamDataObs.subscribe({
      next: (chunk: SocketModel<string>) => {
        this._thinkingService.stopAriaThinking();
        this.consumeSignalRResult(chunk.object);
      },
    });

    this._connectionService.streamCompletedObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isCompleted) => {
        this.isGenerationInProgress = !isCompleted;
        this._nkodaLinkPanelService.startLoading();
      },
    });

    this.onConnectionEstablished(message);
  }

  private onConnectionEstablished(message: string) {
    this._openAiService
      .sendMessage(message)
      .pipe(
        tap((msgResponse: UserMessageDto) => {
          if (this._settingsService.modelConfig.shouldUseContentPrompt) {
            this._openAiService
              .generateNkodaLinks(msgResponse)
              .subscribe()
              .add(() => {
                this._nkodaLinkPanelService.endLoading();
                this._nkodaLinkPanelService.setSessionState(SessionState.Scroll);
              });
          } else {
            this._nkodaLinkPanelService.endLoading();
          }
        }),
      )
      .subscribe({
        next: () => {},
        complete: () => {
          this._openAiService.aiMessage = {};
          this._thinkingService.stopAriaThinking();
          this.isGenerationInProgress = false;
        },
        error: (response: HttpErrorResponse) => {
          let errorMessage =
            this.user.isAdmin || response?.error?.message?.includes('Message limit reached')
              ? response.error.message
              : 'Sorry I am unable to answer your questions at the moment; please try again.';

          if (response?.error?.StatusCode === 401) {
            errorMessage = "You don't have access to this product!";
            this._openAiService.aiMessage.role = this.roleTypes.Error;
            this._openAiService.aiMessage.errorText = errorMessage;
          }

          this._openAiService.aiMessage.errorText = errorMessage;

          this._thinkingService.stopAriaThinking();
          this.isGenerationInProgress = false;
        },
      });
  }

  onEnterPress(event: KeyboardEvent) {
    const isEnterKey = event.key === 'Enter';
    const isShiftPressed = event.shiftKey;

    if (isEnterKey && !isShiftPressed) {
      event.preventDefault();
    }

    if (this.isGenerationInProgress) {
      return;
    }

    if (isEnterKey && !isShiftPressed && this.messageFormGroup.get('message')?.valid) {
      this.sendMessage();
      return;
    }

    if (isEnterKey && !isShiftPressed) {
      this.messageFormGroup.reset();
    }
  }

  scrollChatToBottom(refreshScroll = false, delay = 0, behavior: 'auto' | 'instant' | 'smooth' = 'auto') {
    if (refreshScroll) {
      this.isAutoScrollEnabled = true;
    }

    if (!this.isAutoScrollEnabled) {
      return;
    }

    if (!this.scroll?.nativeElement) return;
    setTimeout(() => {
      this.scroll.nativeElement.scrollTo({
        behavior,
        top: this.scroll.nativeElement.scrollHeight,
      });
    }, delay);
  }

  private consumeSignalRResult(streamChunk: string) {
    if (this._openAiService.aiMessage.text === null || this._openAiService.aiMessage.text === undefined) {
      this._openAiService.aiMessage.text = '';
    }

    this._openAiService.aiMessage.text += streamChunk;
  }

  private handleTouch() {
    this.scroll.nativeElement.addEventListener(
      'touchmove',
      () => {
        this.handleScroll();
      },
      { passive: true },
    );
  }

  private handleWheel() {
    this.scroll.nativeElement.addEventListener(
      'wheel',
      () => {
        this.handleScroll();
      },
      { passive: true },
    );
  }

  private handleScroll() {
    if (
      this.scroll.nativeElement.offsetHeight + this.scroll.nativeElement.scrollTop >=
      this.scroll.nativeElement.scrollHeight
    ) {
      this.isAutoScrollEnabled = true;
      return;
    }

    this.isAutoScrollEnabled = false;
  }

  private initializeComponent() {
    this._unsubscribeObs$ = new Subject();
    this._themeService._themeObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (theme: string) => {
        if (theme === AppTheme.White) {
          this.lottieOptions = {
            path: this.whiteWave,
          };
          return;
        }

        this.lottieOptions = {
          path: this.darkWave,
        };
      },
    });

    this.messageFormGroup = new FormGroup({
      message: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });

    this.messageFormGroup
      ?.get('message')
      ?.valueChanges.pipe(takeUntil(this._unsubscribeObs$))
      .subscribe((text) => {
        this.isSendButtonDisabled = text == null || text == '';
      });

    this._openAiService.sessionModelObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (sessionModels: Array<SessionModel>) => {
        this.isAutoScrollEnabled = true;
        this.loaded = true;

        if (sessionModels && sessionModels.length === 0) {
          this.sessionModel = sessionModels;
          this.selectedSessionModel = {} as SessionModel;
          return;
        }

        this.sessionModel = sessionModels;
        const selectedSessionModel = sessionModels.find((x) => x.isSelected) as SessionModel;
        this.selectedSessionModel = selectedSessionModel;

        if (
          this.selectedSessionModel &&
          this.selectedSessionModel.messages &&
          this.selectedSessionModel.messages.length
        ) {
          const lastMessageId = this.selectedSessionModel.messages[this.selectedSessionModel.messages.length - 1].id;
          this.sectionChange(lastMessageId!);
        } else {
          this._nkodaLinkPanelService.data = {};
        }
      },
      error: () => {},
      complete: () => {},
    });

    this._authService.userObs$
      .pipe(
        takeUntil(this._unsubscribeObs$),
        tap((userInfo) => {
          this.user = userInfo;
        }),
      )
      .subscribe();

    this._sidebarService.showSidebarObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isDisplayed: boolean) => {
        this.isSideBarClosed = !isDisplayed;
      },
    });

    this._openAiService.getUserData().pipe(takeUntil(this._unsubscribeObs$)).subscribe();

    this.setPromptAreaHeight();

    const element = document.getElementById('aria-chat');
    const observer = new MutationObserver(() => {
      if (element?.scrollHeight && element.scrollHeight + 100 > window.innerHeight) {
        this.scrollChatToBottom();
      }
    });

    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });

    this._subscriptionInfo.getB2CProducts().pipe(takeUntil(this._unsubscribeObs$)).subscribe();
    this._subscriptionInfo.getUserProducts().pipe(takeUntil(this._unsubscribeObs$)).subscribe();
    this._subscriptionInfo.getUserSubscriptions().pipe(takeUntil(this._unsubscribeObs$)).subscribe();

    this._settingsService.selectedModelConfigObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (modelConfig) => {
        if (!modelConfig.id) return;
        if (modelConfig) {
          this.modelConfig = modelConfig;
        }
      },
    });

    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.detectCurrentSection();
      });
  }

  detectCurrentSection(): void {
    const sections = document.querySelectorAll('.scroll-section');
    let currentSectionId = '';

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        currentSectionId = section.id;
      }
    });

    if (currentSectionId !== this.currentSection) {
      this.currentSection = currentSectionId;
    }
  }

  setQuickIntroMessage() {
    if (StringUtils.isNullOrEmpty(this.modelConfig?.quickIntro)) return;

    const message = {
      text: this.modelConfig.quickIntro,
      role: RoleType.Aria,
    } as UserMessageDto;

    this._openAiService.setMessage(message);
  }

  private setPromptAreaHeight() {
    const textarea = document.getElementById('prompt-textarea');

    if (!textarea) return;
    textarea.addEventListener('input', () => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    });
  }

  sectionChange(value: string | null) {
    this._chatSectionService.next(value);
  }
}
